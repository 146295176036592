import isDevice from './_isDevice.js' // UserAgentをpc,sp,tabの形で返す
/**
 * 全ブラウザでスクロールをロックする
 * SPのみiOS16未満の環境を考慮して複雑に止める
 * オーバーレイの状態を基準にONOFFを切り替え
 * scrollLock('html,body', '#overlay')
 *
 * @function scrollLock
 * @param  {String} [selector='body']  固定対象セレクタ
 * @param  {String} [trigger='#overlay']  判定用セレクタ
 * @param  {String} [flag='active']  判定用クラス
 * @return {Void}
 *
 * 呼び出し
import scrollLock from './functions/_scrollLock.js' // スクロールロック
scrollLock('body', '#overlay')
 *
 */

const scrollLock = (selector = 'body', trigger = '#overlay', flag = 'active') => {
  const lockClass = 'js-scrollLock--active'
  const lockDOM = document.querySelector(selector)
  const lockFlag = lockDOM.classList.contains(lockClass)
  const targetFlag = document.querySelector(trigger + '.' + flag)

  const html = document.documentElement

  if (lockFlag && targetFlag) {
    // ロック状態で、判定要素もアクティブなら何もしない
    return
  }
  if (!lockFlag && targetFlag) {
    // 非ロック状態で、判定要素がアクティブならロック
    const scrollLockTop = window.pageYOffset // ロックする前にスクロール量を記憶
    if (isDevice === 'sp') {
      // fixedにしてスクロール停止
      // スクロール分だけtopをずらして見た目を維持する
      // 他の処理でスクロール量を計測などしていると不都合がある
      lockDOM.style.position = 'fixed'
      lockDOM.style.top = -1 * scrollLockTop + 'px'
      lockDOM.style.overflowY = 'scroll' // スクロールバーが消えて横幅が変わるのを防止
      lockDOM.dataset.scrollLockTop = scrollLockTop
    } else {
      // overflow: hiddenをかけてスクロール停止
      // iOS15までのiPhoneには効かない
      html.style.overflow = 'hidden'
      html.style.scrollbarGutter = 'stable' // スクロールバーの余白を付ける
    }

    // ロック判定確認用のクラスを付ける
    lockDOM.classList.add(lockClass)
    return
  }
  // それ以外ならロックを解除
  if (isDevice === 'sp') {
    lockDOM.style.position = ''
    lockDOM.style.top = ''
    lockDOM.style.overflowY = ''
    // スクロールさせて位置を戻す
    window.scrollTo({
      top: lockDOM.dataset.scrollLockTop,
    })
  } else {
    html.style.overflow = ''
    html.style.scrollbarGutter = '' // スクロールバーの余白を戻す
  }

  // ロック判定確認用のクラスを外す
  lockDOM.classList.remove(lockClass)
}
export default scrollLock
