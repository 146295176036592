import $ from 'jquery' // jQuery
import './module/_hashScroll' // アンカーリンクスクロール
import './module/_spMenuToggle' // SPメニュー開閉

/* global  */ // tUrl等のグローバル変数を宣言する
import './_top.js'
// import './_form.js'

import { Autoplay, Controller, EffectFade, Navigation, Pagination, Swiper } from 'swiper'
Swiper.use([Autoplay, Controller, EffectFade, Navigation, Pagination]) // Swiper宣言
// その他、Swiperで使う機能があったら上に追加で宣言する
// A11y, Autoplay, Controller, EffectCoverflow, EffectCube, EffectFade, EffectFlip, HashNavigation, History, Keyboard, Lazy, Mousewheel, Navigation, Pagination, Parallax, Scrollbar, Thumbs, Virtual, Zoom,

//
//  #####    ###     #####
//  ##      ## ##   ##   ##
//  #####  ##   ##  ##   ##
//  ##     #######   #####
//  ##     ##   ##  ##
//

$('.js-faq-ques').click(function () {
  $(this).toggleClass('open')
  $(this).next('.js-faq-ans').slideToggle()
})

$('.js-top-case').click(function () {
  let target = $(this).data('target')
  $('.js-top-case').removeClass('current')
  $(this).addClass('current')
  $('.js-case-item').removeClass('active')
  $('.js-case-item-' + target).addClass('active')
})

const mySwiper = new Swiper('#topProSlide', {
  loop: true,
  slidesPerView: 1.2,
  spaceBetween: 15,
  pagination: {
    el: '.swiper-pagination',
  },
})
