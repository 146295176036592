/**
 * 指定されたセレクタに向かってスクロールを行う
 *
 * @function autoScroll
 * @param  {String} point  移動先のセレクタ
 * @param  {String|Number} adjust 追従ヘッダーの高さを加算し、移動先にヘッダーがかぶらないようにする。ヘッダーのセレクタもしくは数値を入れる
 * @param  {Boolean} [anime=true]  アニメーションの有無
 * @param  {Object} callback  コールバック関数
 * @return {Void}
 *
 * 呼び出し
import autoScroll from './functions/_autoScroll.js' // 自動スクロール
autoScroll('#', '#header',true)
 */

const autoScroll = (point, adjust = 0, anime = true, callback = function () {}) => {
  if (isNaN(adjust)) {
    // 数値以外なら要素として高さ取得
    adjust = document.querySelector(adjust).offsetHeight
  }

  // スクロール先要素を確定する
  let target
  if (point === '#' || point === '') {
    // #だけか空ならページトップへ
    target = document.querySelector('html')
  } else {
    // IDなら指定のIDへ
    // IDセレクタを使うと文法違反のID（頭が数字など）が掴めないので属性セレクタを使う
    const targetID = point.replace('#', '')
    target = document.querySelector('[id="' + targetID + '"]')
  }

  if (!target) {
    // 見つからなければ処理終了
    console.log(point + ' is not found.')
    return
  }

  let goto = Math.floor(window.pageYOffset + target.getBoundingClientRect().top - adjust)

  // スクロール後のコールバック処理
  const onScroll = function () {
    if (window.pageYOffset === goto) {
      window.removeEventListener('scroll', onScroll)
      callback()
    }
  }
  window.addEventListener('scroll', onScroll)
  onScroll()

  let behavior = 'instant'
  if (anime) {
    behavior = 'smooth'
  }

  new Promise((resolve) => {
    window.scrollTo({
      top: goto,
      behavior: behavior,
    })
    setTimeout(() => {
      resolve()
    }, 1000)
  }).then(() => {
    // 再計算してズレがあったら再移動
    const gotoReset = Math.floor(window.pageYOffset + target.getBoundingClientRect().top - adjust)

    if (goto === gotoReset) {
      return
    }
    goto = gotoReset
    window.scrollTo({
      top: goto,
      behavior: behavior,
    })
  })
}

export default autoScroll
